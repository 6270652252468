
class Search {

	static name() { return 'Search'; }

	constructor(props, el) {
		this.props = props;
		this.el = el;
		this.$el = $(el);

		this.objects = {
			$clearButton: this.$el.find('[data-action="clear"]'),
			$orderBy: this.$el.find('[name="orderby"]'),
			$form: this.$el.find('form'),
		};

		this.handleClearControl = (e) => {
			const controlName = $(e.currentTarget).data('control-name');
			const controlType = $(e.currentTarget).data('control-type');

			switch (controlType) {
				case 'select': {
					$(`select[name=${controlName}]`).val('');
					break;
				}
				case 'radio': {
					$(`input[name=${controlName}]`).prop('checked', false);
					break;
				}
				default:
					return;
			}
		};

		this.handleOrderBy = () => {
			this.objects.$form.submit();
		}
	}

	init() {
		console.info('~~~ Search ~~~');

		const { $clearButton, $orderBy } = this.objects;

		$clearButton.on('click', this.handleClearControl);
		$orderBy.on('change', this.handleOrderBy);
	}

}

export default Search;
