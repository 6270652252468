
class SignUpForm {

	static name() { return 'SignUpForm'; }

	constructor(props, el) {
		this.props = props;
		this.el = el;
		this.$el = $(el);

		this.objects = {};

		this.handleGlobalValidation = () => {
			// TODO setup utils for global messaging
			if(!this.el.checkValidity() && this.$el.data('global-message')) {
				$(this.$el.data('global-message')).show();
			}
		};
	}

	init() {
		console.info('~~~ SignUpForm ~~~');

		$('#signUp').on('click', this.handleGlobalValidation);
	}

}

export default SignUpForm;
